var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"custom-select",attrs:{"title":_vm.title}},[_c('div',{ref:"multiSelect",staticClass:"multi-select-wrapper"},[(_vm.label)?_c('label',{attrs:{"title":_vm.label}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:{
                'select': true,
                'select--disabled': _vm.disabled,
            },on:{"click":_vm.handleSelectClick}},[_vm._t("select",function(){return [_vm._v(_vm._s(`LOS${_vm.value.value}`))]}),_c('span',{staticClass:"select__arrow",class:_vm.isExpanded && 'select__arrow--expanded'},[_c('i',{staticClass:"el-icon-arrow-up"})])],2),_c('div',{ref:"wrapper",class:{
                'list-wrapper': true,
                'list-wrapper--hidden': !_vm.isExpanded,
            }},[_c('ul',{staticClass:"list-wrapper__list"},[_vm._t("childBefore"),_c('perfect-scrollbar',[_vm._t("topChild"),_vm._l((_vm.itemsList),function(item){return _c('li',{key:item.value,staticClass:"list-wrapper__list__option",class:{
                            'list-wrapper__list__option--selected' : _vm.isSelected(item),
                        },on:{"click":function($event){return _vm.handleOptionClick(item)}}},[_vm._t("item",function(){return [_c('span',[_vm._v(_vm._s(item.name))])]},{"item":item}),(_vm.isAutoscan(item))?_vm._t("title",function(){return [_c('span',{staticClass:"title"},[_vm._v("(Autoscan)")])]}):_vm._e(),(!_vm.isSelectMode)?_c('div',{staticClass:"list-wrapper__list__option__checkbox"},[(_vm.isCheckBoxSelected(item))?_c('i',{staticClass:"icon-Icons_cug"}):_vm._e()]):_c('div',[(_vm.isAutoscan(item))?_c('i',{staticClass:"icon-Icons_cug"}):_vm._e()])],2)})],2)],2),_vm._t("childAfter")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }