var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',{staticClass:"rates-hint",scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" Rates Indicator ")]},proxy:true},{key:"hoverContent",fn:function(){return [_c('i',{staticClass:"icon-Icons_Qusetion-mark"})]},proxy:true}])},[_c('div',[_vm._v(" Show your hotel rates vs. competitors ")]),_c('div',{staticClass:"rates-hint__indicators-bar"},[_c('div',{staticClass:"good"},[_vm._v("Good")]),_c('div',{staticClass:"fair"},[_vm._v("Fair")]),_c('div',{staticClass:"poor"},[_vm._v("Poor")])]),_c('div',{class:[
        'rates-hint__indicator',
        'rates-hint__indicator--no-data'
    ]},[_c('div',[_vm._v(" No Data ")]),_vm._v(" No rooms available for your hotel and competitors ")]),_c('div',{class:[
            'rates-hint__indicator',
            'rates-hint__indicator--sold-out',
        ]},[_c('div',[_vm._v(" Sold Out ")]),_vm._v(" Your hotel is Sold Out, but competitors still have rooms available ")]),_c('div',{class:[
            'rates-hint__indicator',
            'rates-hint__indicator--na',
        ]},[_c('div',[_vm._v(" N/A ")]),_vm._v(" No rooms available for your hotel on a specific filter, but competitors have rooms available ")]),_c('div',{class:[
            'rates-hint__indicator',
        ]},[_c('div',[_vm._v("Out Of Range")]),_vm._v(" Run an On-Demand scan ")]),_c('div',{class:[
            'rates-hint__indicator',
        ]},[_c('div',[_vm._v("LOS Restrictions")]),_vm._v(" Indicates the hotel has a length of stay restrictions or minimum number of nights ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }