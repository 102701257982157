
import { Component, Mixins } from 'vue-property-decorator';
import RatesAnalysisTable from '@/modules/rates/components/analysis/table/rates-analysis-table.vue';
import RatesAnalysisComparison from '@/modules/rates/components/analysis/rates-analysis-filters.vue';
import TablePageLayout from '@/modules/rates/components/table-page-layout.vue';
import RatesPageOtelLogs from '@/modules/open-telemetry/mixins/rates/rates-page-otel-logs.mixin';

@Component({
    components: {
        RatesAnalysisTable,
        RatesAnalysisComparison,
        TablePageLayout,
    },
})
export default class RatesAnalysisTablePage extends Mixins(RatesPageOtelLogs) {}
