
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import formatDate from '../../filters/format-date.filter';

@Component
export default class ScanBtn extends Vue {
    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Function,
    })
    onClick!: (e?: MouseEvent) => void;

    @Prop({
        type: Date,
        default: null,
    })
    lastScanDate!: Date | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    isScanning!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    public handleClick(e: MouseEvent) {
        if (this.onClick) {
            this.onClick(e);
        }

        this.$emit('click', e);
    }

    get formatDate() {
        return formatDate(this.lastScanDate);
    }

    get isPrevMonth() {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();

        if (todayYear > year || (todayYear === year && todayMonth > month)) {
            return true;
        }

        return false;
    }
}
