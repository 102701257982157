
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';

@Component
export default class RatesAnalysisNoData extends Vue {
    @Inject(RatesAnalysisServiceS)
    ratesAnalysisService!: RatesAnalysisService;

    @Inject(RatesAnalysisFiltersServiceS)
    ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    get filterCurrentName() {
        return this.ratesAnalysisFiltersService.filterComparisonName;
    }

    get noCompareValues() {
        return !this.ratesAnalysisFiltersService.comparisonValues.length;
    }

    handleRefresh() {
        this.ratesAnalysisService.resetLoading();
    }
}
